import Head from "./component/Head/head"
import Footer from "./component/Footer/footer"
import Index from "./pages/Index/index"
import '@/assets/css/common.css'

function App() {
  return (
    <div className="bg-img">
      <Head></Head>
      <Index></Index>
      <Footer></Footer>
    </div>
  )
}

export default App

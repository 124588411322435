/* eslint-disable no-undef */

import { useEffect } from "react"

let viewerArr = []
export function ViewerCanvas({
  id,
  data,
  index,
  style,
  width,
  height,
  canvasStyle,
  moleculeStyle,
}) {
  useEffect(() => {
    let element2 = document.getElementById(id)
    element2.width = width
    element2.height = height
    // element2.style.opacity = Opacity

    // Setup canvas
    if (viewerArr[index]) {
    } else {
      viewerArr[index] = new ChemDoodle.ViewerCanvas(id)
      viewerArr[index].styles = {
        ...viewerArr[index].styles,
        ...canvasStyle,
      }
    }

    // Setup molecule
    const molecule = ChemDoodle.readMOL(data)
    for (const key in moleculeStyle) {
      molecule[key](moleculeStyle[key])
    }

    // Load molecule in canvas
    viewerArr[index] && viewerArr[index].loadMolecule(molecule)

    return () => {
      // rotator.stopAnimation()
    }
  }, [id, data, canvasStyle, moleculeStyle])

  return <canvas id={id} style={style} width={width} height={height} />
}

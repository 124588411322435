/* eslint-disable no-undef */

import { useEffect } from "react"


let rotatorArr = []
export function RotateCanvas({
  id,
  index,
  data,
  style = {
    // backgroundColor: "transparent",
    // border: 'none',
  },
  width,
  height,
}) {
  useEffect(() => {
    const element2 = document.getElementById(id)
    element2.width = width
    element2.height = height

    // Setup canvas
    if (rotatorArr[index]) {
    } else {
      rotatorArr[index] = new ChemDoodle.RotatorCanvas3D(id)
      rotatorArr[index].dblclick = null
      rotatorArr[index].styles.backgroundColor = "transparent"
      rotatorArr[index].styles.set3DRepresentation("Ball and Stick")
    }

    // Setup molecule
    const molecule = ChemDoodle.readMOL(data, 1)

    // Load molecule in canvas
    rotatorArr[index] && rotatorArr[index].loadMolecule(molecule)
    rotatorArr[index] && rotatorArr[index].startAnimation()

    return () => {
      rotatorArr[index] && rotatorArr[index].stopAnimation()
    }
  }, [id, data, height])

  return <canvas id={id} style={style} width={width} height={height} />
}

export default function Head() {
  return (
    <div
      style={
        {
          paddingTop: "30px",
        }
      }>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          color: "white",
          fontSize:'4vh',
          margin:'50px 0 50px 0',
        //   background: "red",
        }}>
        超导发掘平台
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        //   marginTop: "30px",
          height: "40px",
          lineHeight: "40px",
        }}>
        <input
          style={{
            width:'400px',
            border: "none",
            borderRadius: "7px 0 0 7px",
          }}></input>
        <button
          onClick={() => window.open("https://www.chemputer.cn/atomify/")}
          style={{
            width:'120px',
            borderRadius: "0 7px 7px 0",
            border: "none",
            fontSize: "15px",
            padding: "0 10px 0 10px",
          }}>
          探索未知
        </button>
      </div>
    </div>
  )
}

import { Carousel } from "@arco-design/web-react"
import "@arco-design/web-react/dist/css/arco.css"
import "@/assets/css/common.css"
import { molUrlArr } from "@/config/molToJson"
import RotateCanvasExample from "@/component/RotateCanvas/RotateCanvasExample"
import ViewerCanvasExample from "@/component/ViewerCanvas/ViewerCanvasExample"
import { useEffect, useState } from "react"

// 获取浏览器窗口的内部宽度
let windowWidth = document.documentElement.clientWidth

// 获取视口高度
let screenHeight = window.innerHeight
const WIDTH = windowWidth - 50
const HEIGHT = screenHeight - screenHeight * 0.4
const SIDEWIDTH = WIDTH * 0.6

export default function CarouselArco() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [prevIndex, setPrevIndex] = useState(0)
  const [count, setCount] = useState(0)
  const handleCarouselChange = (index, prevIndex, isManual) => {
    setCurrentIndex(index)
    setPrevIndex(prevIndex)
    setCount((count + 1) % molUrlArr.length)
  }
  useEffect(() => {
    // console.log(currentIndex, "currentIndex变了")
    // const checkIsMobile = () => setIsMobile(window.innerWidth <= 768)
    // checkIsMobile() // 初始检查
    // console.log(isMobile,'是否手机',window.innerWidth)
    // window.addEventListener("resize", checkIsMobile) // 监听窗口变化
    // return () => window.removeEventListener("resize", checkIsMobile)
  }, [currentIndex])

  return (
    <Carousel
      autoPlay={{ interval: 5000, hoverToPause: false }}
        // autoPlay={false}
      animation={isMobile ? "slide" : "card"}
      showArrow="never"
      miniRender={true}
      direction="horizontal"
      indicatorType="never"
      onChange={handleCarouselChange}
      style={{
        width: WIDTH,
        height: HEIGHT,
      }}>
      {[0, 1, 2].map((item) => (
        <div
          key={item}
          style={{
            width: isMobile ? WIDTH : SIDEWIDTH,
            height: HEIGHT,
            color: "#fff",
            // background:'PINK'
          }}>
          <ViewerCanvasExample
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
            }}
            id={`viewer-canvas${item}`}
            index={item}
            current={
              item === prevIndex
                ? count
                : item === currentIndex
                ? (count + 1) % molUrlArr.length
                : (count + 2) % molUrlArr.length
            }
            width={isMobile ? WIDTH : SIDEWIDTH}
            height={HEIGHT}
          />
          <RotateCanvasExample
            id={`rotate-canvas${item}`}
            index={item}
            current={
              item === prevIndex
                ? count
                : item === currentIndex
                ? (count + 1) % molUrlArr.length
                : (count + 2) % molUrlArr.length
            }
            width={isMobile ? WIDTH : SIDEWIDTH}
            height={HEIGHT}
            IsShowProperties={item === currentIndex}
          />
        </div>
      ))}
    </Carousel>
  )
}

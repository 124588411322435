import { useEffect, useState } from "react"
import { RotateCanvas } from "./RotateCanvas"
import { molUrlArr, BASEURL } from "@/config/molToJson"
import useFormatMol from "@/hook/useFormatMol.js"

function RotateCanvasExample({
  current,
  width,
  height,
  id,
  IsShowProperties,
  index,
}) {
  const UseFormatMol = useFormatMol()
  const [mol, setMol] = useState("")
  const [properties, setProperties] = useState()
  const [name, setName] = useState()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    fetch(`${BASEURL}${molUrlArr[current]}.mol`)
      .then((data) => {
        return data.text()
      })
      .then((res) => {
        setMol(res)
      })

    fetch(`${BASEURL}${molUrlArr[current]}.json`)
      .then((data) => {
        return data.text()
      })
      .then((res) => {
        const data = UseFormatMol.getMolJson(res)
        setName({
          name: data.name,
          formula: data.formula,
        })
        setProperties(data.properties)
      })
  }, [mol, current])

  return (
    <div
      style={{
        position: "relative",
        fontSize: isMobile ? "80%" : "",
      }}>
      {IsShowProperties && (
        <div
          style={{
            position: "absolute",
            color: "white",
            top: 10,
            left: 10,
            // background: "red",
          }}>
          {properties &&
            properties.map((item, index) => {
              return (
                UseFormatMol.upComparison[item.TOCHeading] && (
                  <div key={`properties${index}`}>
                    {UseFormatMol.upComparison[item.TOCHeading]}：
                    {/* {JSON.stringify(item.Information[0].Value)} */}
                    {item.Information[0].Value.StringWithMarkup
                      ? `${
                          item.Information[0].Value.StringWithMarkup[0].String
                        }${
                          item.Information[0].Value.Unit
                            ? item.Information[0].Value.Unit
                            : ""
                        }`
                      : item.Information[0].Value.Number}
                  </div>
                )
              )
            })}
        </div>
      )}

      {IsShowProperties && (
        <div
          style={{
            position: "absolute",
            color: "white",
            bottom: 10,
            left: 10,
            // background: "red",
          }}>
          {properties &&
            properties.map((item, index) => {
              return (
                UseFormatMol.downComparison[item.TOCHeading] && (
                  <div key={`properties${index}`}>
                    {UseFormatMol.downComparison[item.TOCHeading]}：
                    {/* {JSON.stringify(item.Information[0].Value)} */}
                    {item.Information[0].Value.StringWithMarkup
                      ? `${
                          item.Information[0].Value.StringWithMarkup[0].String
                        }${
                          item.Information[0].Value.Unit
                            ? item.Information[0].Value.Unit
                            : ""
                        }`
                      : item.Information[0].Value.Number}
                  </div>
                )
              )
            })}
        </div>
      )}
      {IsShowProperties && (
        <div
          style={{
            position: "absolute",
            color: "white",
            right: 10,
            bottom: 10,
            // background: "red",
          }}>
          {name &&
            Object.keys(name).map((k, v) => {
              return (
                <div key={k}>
                  {UseFormatMol.nameComparison[k]}：{name[k]}
                </div>
              )
            })}
        </div>
      )}
      <RotateCanvas
        id={id}
        data={mol}
        width={width}
        height={height}
        index={index}
      />
    </div>
  )
}

export default RotateCanvasExample
